// material-ui
import lightLogo from 'assets/images/newlogo2.png';
import logo from 'assets/images/logo-new1.png';
// import logo from 'assets/images/logo.png';

// ==============================|| LOGO ||============================== //

const Logo = ({pageLayout}) => <img src={ pageLayout === "onboarding" ? lightLogo : logo} alt="FIXE" width="116" />;

export default Logo;
