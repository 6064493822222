import { apiGet, awsApiUpdate, apiUpdate, apiDelete, apiPostDjango, apiDjangoGet } from './apiClient';


//---------------------------------------------------------------------------------------
// APIs for Groups
// 1. create group
// 2. get group list
// 3. update group
//
const createGroupAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/groups/', data });
    return response;
};
const getGroupListAPI = async () => apiDjangoGet({ url: `/groups` });
// const updateGroupAPI = async ({ id, data }) => apiUpdate({ url: `/groups/${id}/`, data });

const updateGroupAPI = async ({ id, data }) => {
    console.log('updateGroupAPI pppp', id, data);
    console.log('updateGroupAPI pppp', `/groups/${id}/`);
    const response = await apiUpdate({ url: `/groups/${id}/`, data });
    return response;
};

//---------------------------------------------------------------------------------------

// Client Requests
const getClientListAPI = async () => apiGet({ url: `/org/list` });

const getDjangoClientListAPI = async () => apiDjangoGet({ url: `/clients` });

// ClientLocation methods
const getClientLocationAPI = async ({ locationId }) => apiDjangoGet({ url: `/locations/${locationId}` });
const updateClientLocationAPI = async ({ locationId, data }) => apiUpdate({ url: `/locations/${locationId}/`, data });
const getClientLocationsListAPI = async () => apiDjangoGet({ url: `/locations` });

const getClientAPI = async ({ clientID }) => apiGet({ url: `/org?id=${clientID}` });

const updateClientAPI = async ({ id, data }) => awsApiUpdate({ url: `/org?id=${id}`, data });

const createClientAPI = async ({ data }) => undefined;
// const url = `${DOMAIN}client`;
// const response = await apiPost({ method: 'POST', url, body });
//     return undefined;
// };

const onboardClientAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/clients/onboard', data });
    return response;
};

const updateClientDjangoAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/clients/${id}/`, data });
    return response;
};

const deleteClientAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/clients/${id}/` });
    return response;
};





const newClientOnboardingApiPayload = {
    client: {
        name: '',
        plan: '',
        pricing: '',
        support_email: '',
        invited_by_user_id: ''
    },
    user: {
        first_name: '',
        last_name: '',
        email: ''
    },
    meta: {}
};

export {
    getClientListAPI,
    getClientAPI,
    updateClientAPI,
    createClientAPI,
    deleteClientAPI,
    onboardClientAPI,
    updateClientDjangoAPI,
    newClientOnboardingApiPayload,
    getDjangoClientListAPI,
    getClientLocationAPI,
    updateClientLocationAPI,
    getClientLocationsListAPI,
    createGroupAPI,
    getGroupListAPI,
    updateGroupAPI,
};
