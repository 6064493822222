/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    showBackdrop: false,
    backdropMessage: '',
    action: false,
    showMessage: false,
    message: 'Something went wrong',
    variant: 'alert',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const showSuccessMessage = ({ state, message }) => {
    const newState = {
        ...state,
        isLoading: false,
        showMessage: true,
        alertSeverity: 'success',
        variant: 'alert',
        message
    };
    return newState;
};

// ==============================|| REDUX STORE: STATUS DATA ||============================== //

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        USER_MESSAGE(state, action) {
            console.log('>>>>>USER MESSAGE');
            console.log(action);
            const { payload } = action;
            // console.log(state);
            if (payload?.message === 'No default location found') {
                state.isLoading = false;
                state.showBackdrop = false;
                return; // Do nothing for this specific case
            }
            if (payload?.message === 'Failed to retrieve connected app details.') {
                state.isLoading = false;
                state.showBackdrop = false;
                return; // Do nothing for this specific case
            }
            if (payload?.message === 'No matching period found for today.') {
                state.isLoading = false;
                state.showBackdrop = false;
                return; // Do nothing for this specific case
            }
            state.action = !state.action;
            state.showMessage = true;
            state.message = payload.message ? payload.message.toString() : initialState.message;
            state.alertSeverity = payload.alertSeverity ? payload.alertSeverity : initialState.alertSeverity;
            state.isLoading = false;
            state.showBackdrop = false;
        },
        START_LOADING(state, action) {
            state.isLoading = true;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = action.payload?.backdropMessage;
            if (action.payload?.backdrop) {
                state.showBackdrop = true;
            }
        },
        endLoading(state) {
            state.isLoading = false;
            state.showBackdrop = false;
            state.backdropMessage = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.includes('account/user/update/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating User';
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/user/update/fulfilled'),
                (state) => {
                    state.isLoading = false;
                    state.showBackdrop = false;
                    // state.backdropMessage = 'Updating User';
                }
            )
            .addMatcher(
                (action) => action.type.includes('user/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating New User';
                }
            )
            .addMatcher(
                (action) => action.type.includes('award/update/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.showMessage = false;
                }
            )
            .addMatcher(
                (action) => action.type.includes('entry/update/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating Entry/Uploading Documents';
                }
            )
            .addMatcher(
                (action) => action.type.includes('award/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.showMessage = false;
                }
            )
            .addMatcher(
                (action) => action.type.includes('entry/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating Entry/Uploading Documents';
                }
            )
            // .addMatcher(
            //     (action) => action.type.includes('award/update/fulfilled'),
            //     (state) => showSuccessMessage({ state, message: 'Award Updated' })
            // )
            // .addMatcher(
            //     (action) => action.type.includes('entry/update/fulfilled'),
            //     (state) => showSuccessMessage({ state, message: 'Entry Updated' })
            // )
            // .addMatcher(
            //     (action) => action.type.includes('award/create/fulfilled'),
            //     (state) => showSuccessMessage({ state, message: 'Award Created' })
            // )
            // .addMatcher(
            //     (action) => action.type.includes('user/create/fulfilled'),
            //     (state) => showSuccessMessage({ state, message: 'User Successfully Created' })
            // )
            // .addMatcher(
            //     (action) => action.type.includes('entry/create/fulfilled'),
            //     (state) => showSuccessMessage({ state, message: 'Entry Created' })
            // )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.showMessage = true;
                    state.message = action?.error?.message;
                    state.alertSeverity = 'error';
                    state.isLoading = false;
                    state.showBackdrop = false;
                    state.variant = 'alert';
                }
            );
        // .addMatcher(
        //     (action) => action.type.endsWith('/fulfilled'),
        //     (state) => {
        //         state.isLoading = false;
        //         state.showBackdrop = false;
        //     }
        // )
        // .addMatcher(
        //     (action) => action.type.endsWith('/pending'),
        //     (state) => {
        //         state.open = false;
        //         state.message = '';
        //         state.isLoading = true;
        //     }
        // );
    }
});
export const isLoading = (state) => state?.status?.isLoading;
export default statusSlice.reducer;
export { showSuccessMessage };
export const { USER_MESSAGE, START_LOADING, endLoading } = statusSlice.actions;
