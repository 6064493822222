/* eslint-disable */

import {
    apiPostDjango,
    apiPostDjangoWithResponse,
    apiDjangoGet,
    apiDjangoGetWithReponse,
    apiDjangoDelete,
    djangoApiClient,
    apiUpdate,
    apiDelete,
    apiPostPdfDjango
} from './apiClient';
import getResponseStatus from './util';

const CREATE_REPORT_URL = '/create-report/';

const createReport = async ({ data }) => {
    try {
        const response = await apiPostDjango({ url: CREATE_REPORT_URL, data });

        console.log('>>>>createReport response', response);

        if (response.status == 401 && response?.data?.redirectUrl) {
            console.log('>>>>createReport unauthenticated. Entering oauth reauth flow...');
            const redirectUrl = `${process.env.REACT_APP_API_SERVER_URL}${response.data.redirectUrl}`;
            console.log('>>>>createReport redirecting to', redirectUrl);
            window.location.href = redirectUrl;
            return null;
        }

        // Check if the response is a redirect (status code in the 3xx range)
        if (response.status == 300 && response.status < 400) {
            // Redirect the user to the new URL returned by the Django API
            const newUrl = response.headers.get('Location');
            window.location.href = newUrl;
            return null;
        }
        return response;
    } catch (error) {
        // Handle other errors, e.g., network errors
        console.error('>>>>>>>>createReport ERROR:', error);
        return null;
    }
};

const checkAccessToken = async () => {
    try {
        const response = await apiDjangoGet({ url: '/access-token/' }); // Modify the URL according to your Django API
        const accessToken = response.data.access_token;
        if (accessToken) {
            // Access token is available, stop polling and redirect to the desired page with the access token
            window.location.href = `http://localhost:3000/am/reports?accessToken=${accessToken}`;
        } else {
            // Access token is not yet available, continue polling after a delay
            setTimeout(checkAccessToken, 1000); // Poll every 1 second (adjust the delay as needed)
        }
    } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
    }
};

const connectQBO = async ({ locationId }) => {
    const NAME = 'connectQBO()';
    try {
        const response = await apiDjangoGet({ url: `/locations/${locationId}/qbo-auth-url` });
        console.log('>>>>connectQBO response', response);
        const redirectUrl = response?.data?.authUrl;
        if (redirectUrl != null) {
            // Redirect the user to the auth URL
            window.location.href = redirectUrl;
        }
        return null;
    } catch (error) {
        console.error(NAME, error);
        return null;
    }
};

const initiateQBOAuth = async () => {
    try {
        const response = await apiDjangoGet({ url: '/auth/' });
        const redirectUrl = response.redirectUrl;
        // Redirect the user to the auth URL
        // window.location.href = redirectUrl;
        window.location.assign(redirectUrl);

        // Start the polling mechanism after the redirect
        checkAccessToken();
    } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
    }
};

const getReportListAPI = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/reports', params });
    return response;
};

const deleteReportAPI = async ({ id }) => {
    const response = await apiDjangoDelete({ url: `/reports/${id}/` });
    return response;
};

const getReportData = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/report', params });
    return response?.data;
};

const getReportDataPreview = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/report-data', params });
    return response?.data;
};

const saveAccessToken = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/callback', params });
    const responseOk = response?.status >= 200 && response?.status < 300;
    return responseOk;
};

const saveAccountMappingAPI = async ({ locationId, data }) => {
    const response = await apiPostDjango({ url: `/locations/${locationId}/save-account-mapping/`, data });
    return response;
};

const getGroupAccountFilterData = async ({ data, params }) => {
    // const data = { email, userType }
    const response = await apiPostDjango({ url: `/accounts`, data, params });
    return response;
};

const getGroupListFilterData = async ({ data, params }) => {
    // const data = { email, userType }
    const response = await apiPostDjango({ url: `/reports/groups/`, data, params });
    return response;
};

const getReportDetails = async (data, type) => {
    ///analytics/reports?group_id=2&report_type=profit-loss&start_date=2024-05-01&end_date=2024-08-01
    const response = await apiPostDjango({ url: `/analytics/${type}/`, data });
    return response;
};

const getCompareReportData = async (data, type) => {
    const response = await apiPostDjango({ url: `/analytics/${type}/compare/`, data });
    return response;
};
const getPAndLDetailReport = async (data) => {
    const response = await apiPostDjango({ url: `/analytics/profit-loss/detail/`, data });
    console.log('getPAndLDetailReport', response);
    return response;
};
const getMonthlyReport = async (data, type) => {
    const response = await apiPostDjango({ url: `/analytics/${type}/ByMonth/`, data });
    return response;
};

const generateApAgingReport = async (data) => {
    const response = await apiPostDjango({ url: `/analytics/ap-aging/`, data });
    return response;
};
const generateArAgingReport = async (data) => {
    const response = await apiPostDjango({ url: `/analytics/ar-aging/`, data });
    return response;
};

// ---------------------------------------------------------------------------------------
// Group Report List
// ---------------------------------------------------------------------------------------
const getAMGroupReportListAPI = async ({ groupId, params }) => {
    return await apiDjangoGet({ url: `/analytics/report-list/group/${groupId}/`, params });
};
const deleteAMGroupReport = async ({ reportId }) => {
    return await apiDelete({ url: `/analytics/delete-report/${reportId}/` });
};
const approveAMGroupReport = async ({ reportId, data }) => {
    return await apiUpdate({ url: `/analytics/approve-report/${reportId}/`, data });
};
const getReportsCount = async (data) => {
    try {
        const response = await apiPostDjangoWithResponse({ url: `/analytics/operations/report-locations/`, data });
        if (response.status !== 201 && response.status !== 200) {
            return getResponseStatus(response);
        }
        return response;
    } catch (error) {
        return getResponseStatus(error);
    }
};

// ---------------------------------------------------------------------------------------
// Group list (for downloads)
// ---------------------------------------------------------------------------------------
const getAllLocations = async () => {
    const response = await apiDjangoGetWithReponse({ url: `/analytics/download/locations/` });
    return getResponseStatus(response);
};
const getAllGroups = async () => {
    const response = await apiDjangoGetWithReponse({ url: `/analytics/download/groups/` });
    return getResponseStatus(response);
};
const getAllContacts = async () => {
    const response = await apiDjangoGetWithReponse({ url: `/analytics/download/contacts/` });
    return getResponseStatus(response);
};

// ---------------------------------------------------------------------------------------
// Group Report List for Client
// ---------------------------------------------------------------------------------------

const getPendingReportList = async ({ data }) => {
    return await apiPostDjango({ url: `/analytics/report-list/pending-approval/`, data });
};

const getAllReportList = async ({ email, data, params }) => {
    return await apiPostDjango({ url: `/analytics/reports-list/${email}/`, data, params });
};
const getJustReceivedReportList = async ({ email, params }) => {
    return await apiPostDjango({ url: `/analytics/report-list/just-received/${email}/`, params });
};

const sendReport = async (data) => {
    const response = await apiPostDjango({ url: `/analytics/send/reports-package/`, data });
    return response;
};
const generateBalanceSheetPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/generatebalance_sheet/`, data });
    return reponse;
};
const generateBalanceSheetComparePdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_balance_sheet_compare_pdf/`, data });
    return reponse;
};
const generatePAndLPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_pdf/`, data });
    return reponse;
};
const generatePAndLComparePdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_profit_loss_compare_pdf/`, data });
    return reponse;
};
const generatePAndLDetailPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_profit_loss_generalLedgerpdf/`, data });
    return reponse;
};
const generatePAndLMonthlyPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_profit_loss_monthpdf/`, data });
    return reponse;
};
const generateBalanceSheetMonthlyPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_balance_sheet_monthpdf/`, data });
    return reponse;
};
const generatePAndLFlashReportPdf = async (data) => {
    const reponse = await apiPostPdfDjango({ url: `/analytics/genrate_profit_loss_flashpdf/`, data });
    return reponse;
};

const getClientDateRanges = async (id, year) => {
    const response = await apiDjangoGet({ url: `analytics/config/group/${id}/?year=${year}` });
    return response;
};

const getClientAllDateRanges = async (id) => {
    const response = await apiDjangoGet({ url: `analytics/config/group/${id}/` });
    return response;
};
const resetReportConfig = async (id, year, type) => {
    const response = await apiPostDjango({ url: `analytics/config/group/${id}/reset/${year}/${type}/` });
    return response;
};

//  Flash REport personalization api

const getFlashReportCustomData = async (id) => {
    const response = await apiDjangoGet({ url: `administration/personalization/${id}/` });
    return response;
};
const updateFlashReportCustomeData = async (data, id) => {
    const response = await apiPostDjango({ url: `/administration/personalization/${id}/`, data });
    return response;
};

// ---------------------------------------------------------------------------------------
// Group Compare Report List for Client
// ---------------------------------------------------------------------------------------

const getGroupCompareReportAPI = async (data) => {
    return await apiPostDjango({ url: `/analytics/profit-loss/location/compare/`, data });
};

const getLocationsConnectedToApp = async (id) => {
    return await apiDjangoGet({ url: `/analytics/connected/${id}/` });
};

const generateGroupCompareReportPDF = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrate_profit_loss_locationpdf/`, data });
};

// COGS APIS
const getCogsData = async (id) => {
    return await apiDjangoGet({ url: `/analytics/group/${id}/account-mapping/ ` });
};
const updateCogsData = async (id, data) => {
    return await apiPostDjango({ url: `/analytics/group/${id}/save-account-mapping/ `, data });
};

// CSV API
const genrateProfitLossCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_csv/`, data });
};
const genrateBalanceSheetCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_csvdetail/`, data });
};
const genratePAndLDetailCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genratebalance_sheet_csv/`, data });
};
const genrateBalanceSheetCompareCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genratebalance_sheetcsvcompare/`, data });
};
const genrateProfitLossCompareCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_csvcompare/`, data });
};
const genrateProfitLossFlashCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_flashcsv/`, data });
};
const genrateLocationCompareCSV = async (data) => {
    return await apiPostPdfDjango({ url: `/analytics/genrateprofit_loss_csvlocationcompare/`, data });
};

// ---------------------------------------------------------------------------------------
// Record events
// ---------------------------------------------------------------------------------------

const recordEvents = async ({ data }) => {
    return await apiPostDjango({ url: `/analytics/events/`, data });
};

// ---------------------------------------------------------------------------------------
// KPIs
// ---------------------------------------------------------------------------------------
const getKpisReportData=async(data)=>{
    const response = await apiPostDjango({ url:'/analytics/kpis/',data });
    return response;
}

const getKpisDefaultLocation=async(data)=>{
    const response = await apiPostDjango({ url:'/administration/personalization/default-location/',data });
    return response;
}

const setGoalsData=async(data,reqData)=>{
    const response = await apiUpdate({ url:`/administration/personalization/${reqData.groupId}/goals/`,data });
    return response;
}

const getGroupData=async(data)=>{
    const response = await apiPostDjango({ url:`/administration/personalization/${data.groupId}/`, });
    return response;
}

export {
    createReport,
    initiateQBOAuth,
    getReportData,
    getReportListAPI,
    deleteReportAPI,
    saveAccessToken,
    getReportDataPreview,
    saveAccountMappingAPI,
    connectQBO,
    getGroupAccountFilterData,
    getGroupListFilterData,
    getReportDetails,
    getCompareReportData,
    getAMGroupReportListAPI,
    deleteAMGroupReport,
    approveAMGroupReport,
    getPendingReportList,
    getAllReportList,
    getJustReceivedReportList,
    getPAndLDetailReport,
    getMonthlyReport,
    sendReport,
    generatePAndLComparePdf,
    generatePAndLPdf,
    generateBalanceSheetComparePdf,
    generateBalanceSheetPdf,
    getClientDateRanges,
    getClientAllDateRanges,
    resetReportConfig,
    generatePAndLDetailPdf,
    generatePAndLMonthlyPdf,
    generatePAndLFlashReportPdf,
    generateBalanceSheetMonthlyPdf,
    getGroupCompareReportAPI,
    getLocationsConnectedToApp,
    generateGroupCompareReportPDF,
    getCogsData,
    updateCogsData,
    genrateProfitLossCSV,
    genrateBalanceSheetCSV,
    genratePAndLDetailCSV,
    genrateBalanceSheetCompareCSV,
    genrateProfitLossCompareCSV,
    genrateProfitLossFlashCSV,
    genrateLocationCompareCSV,
    getFlashReportCustomData,
    updateFlashReportCustomeData,
    getReportsCount,
    getAllLocations,
    getAllGroups,
    getAllContacts,
    generateApAgingReport,
    generateArAgingReport,
    recordEvents,
    getKpisReportData,
    getKpisDefaultLocation,
    setGoalsData,
    getGroupData
};
